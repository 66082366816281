import { Breadcrumb, Layout, Menu, Row, Col, Card } from "antd";
import React, { useState } from "react";
import "./App.less";
import DebounceSelect from "./component/DebounceSelect";
import AdvancedSearchForm from "./component/AdvancedSearchForm";
import TableExample from "./component/TableExample";
import { useTranslation } from "react-i18next";

const { Header, Content, Footer } = Layout;

// Usage of DebounceSelect
interface UserValue {
  label: string;
  value: string;
}

async function fetchUserList(username: string): Promise<UserValue[]> {
  console.log("fetching user", username);

  return fetch("https://randomuser.me/api/?results=5")
    .then((response) => response.json())
    .then((body) =>
      body.results.map(
        (user: {
          name: { first: string; last: string };
          login: { username: string };
        }) => ({
          label: `${user.name.first} ${user.name.last}`,
          value: user.login.username,
        })
      )
    );
}

const App: React.FC = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState<UserValue[]>([]);

  return (
    <Layout className="layout">
      <Header>
        <div>
          <img className="logo" src="./axa-logo.png" alt="AXA logo" />
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          style={{ justifyContent: "center" }}
        >
          <Menu.Item>
            <DebounceSelect
              mode="multiple"
              value={value}
              placeholder="Select users"
              fetchOptions={fetchUserList}
              onChange={(newValue) => {
                setValue(newValue as UserValue[]);
              }}
              style={{ width: "500px" }}
            />
          </Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: "0 50px" }}>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>{t("project.name", "ERM")} 🚀 </Breadcrumb.Item>
        </Breadcrumb>
        <div className="site-layout-content">
          <AdvancedSearchForm />
          <div className="search-result-list">{t("result_list")}</div>
        </div>
        <div className="site-card-wrapper" style={{ marginTop: "30px" }}>
          <Row gutter={16}>
            <Col span={8}>
              <Card title={t("card.totalEmployee")} bordered={false}>
                Card content
              </Card>
            </Col>
            <Col span={8}>
              <Card title={t("card.keyPeople")} bordered={false}>
                Card content
              </Card>
            </Col>
            <Col span={8}>
              <Card title={t("card.talentProgram")} bordered={false}>
                Card content
              </Card>
            </Col>
          </Row>
        </div>
        <div className="site-card-wrapper" style={{ marginTop: "30px" }}>
          <TableExample />
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>{t("project.copyright")}</Footer>
    </Layout>
  );
};

export default App;
